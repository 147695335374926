import { faUpload, faHistory, faHome, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuProps, Space, Typography } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { HISTORY_ROUTE } from "templates/history/history.routes";
import { useAuthContext } from "logic/auth-context";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { useSidebarMenuContext } from "../context";
import { SURVEYS_ROUTE } from "templates/surveys/survey.routes";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import { STUDY_ROUTE } from "templates/studies/study.routes";
import { RAG_ROUTE } from "templates/rag/rag.routes";
import { handleNewStudy } from "@/store/modules/study/utils/hanlde-new-study";
import { PLANS_ROUTE } from "@/templates/plans/plans.routes";
import { PartitionOutlined } from "@ant-design/icons";
import {
  usePollHistoryStudyQuery,
  // useRegenerateHistoryStudyMutation,
} from "@/store/modules/history/slice";
// import { useDeleteStudyMutation } from "@/store/modules/study/slice";

export enum TopMenuKeys {
  HOME = "home",
  SURVEYS = "surveys",
  SEE_ALL_HISTORY = "see-all-history",
  RECENT_STUDIES = "recent-studies",
  RAG = "rag",
  RECENT_PLANS = "recent-plans",
  SEE_ALL_PLANS = "see-all-plans",
}

type TopMenuProps = {
  collapsed?: boolean;
};

export const TopMenu: React.FC<TopMenuProps> = ({ collapsed }) => {
  const auth = useAuthContext();
  // Router
  const router = useRouter();

  const workspaces = useAppSelector((state) => state.workspaces.data);

  const history = useAppSelector((state) => state.history);
  const studyPlansList = useAppSelector((state) => state.studyPlans.data.studyPlansList);
  const activeSubscription = useAppSelector(
    (state) => state.workspaces.data.workspace?.activeSubscription
  );
  const isSignedIn = useMemo(() => auth.session.user, [auth.session.user]);

  const recentStudies = useMemo(() => history.data?.slice(0, 3), [history.data]);

  const executingStudies = useMemo(
    () => recentStudies?.filter((study) => study.status === "interviewing"),
    [recentStudies]
  );

  // const [deleteStudy] = useDeleteStudyMutation();

  // const [regenerateStudy] = useRegenerateHistoryStudyMutation();

  usePollHistoryStudyQuery(
    {
      studyIds: executingStudies?.map((study) => study.id) || [],
    },
    {
      skip: !executingStudies?.length,
      pollingInterval: 60000,
    }
  );

  const { ragEnabled, surveysEnabled, plansEnabled } = useSidebarMenuContext();

  // Top Menu Items
  const topMenuItems: Required<MenuProps>["items"] = useMemo(() => {
    // Home menu item
    const newStudy: ItemType = {
      label: "Home",
      key: TopMenuKeys.HOME,
      icon: <FontAwesomeIcon icon={faHome} />,
    };

    const surveys: ItemType = {
      label: "Surveys (Beta)",
      key: TopMenuKeys.SURVEYS,
      icon: <FontAwesomeIcon icon={faWpforms} />,
    };

    // Recent Studies (Collapsible menu item)
    const recentStudiesItems: ItemType = {
      label: "Recent studies",
      key: TopMenuKeys.RECENT_STUDIES,
      icon: <FontAwesomeIcon icon={faHistory} />,
      children: recentStudies
        ?.map((item) => ({
          label: (
            // <Flex justify="space-between" align="center">
            // <Tooltip
            //   title={item.status === "interviewing" ? "Study is still running" : ""}
            //   placement="right"
            // >
            <Space>
              <Typography.Text
                style={{ fontSize: 14, color: item.status === "failed" ? "red" : "#ffffff" }}
              >
                {item.description}
              </Typography.Text>
              {item.planId && <FontAwesomeIcon icon={faLink} opacity={0.5} />}
            </Space>
            // </Tooltip>
            /* {item.status === "failed" && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        type: "group",
                        label: (
                          <Typography.Text style={{ fontSize: 14, color: "red" }}>
                            Study failed
                          </Typography.Text>
                        ),
                        key: "delete",
                        children: [
                          {
                            label: "Retry",
                            key: "retry",
                            icon: <FontAwesomeIcon icon={faArrowRotateRight} />,
                            onClick: () => {
                              regenerateStudy({ studyId: item.id });
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            icon: <FontAwesomeIcon icon={faTrash} />,
                            onClick: () => {
                              deleteStudy({ studyId: item.id });
                            },
                          },
                        ],
                      },
                    ],
                  }}
                >
                  <Button
                    size="small"
                    style={{
                      background: "rgba(255, 255, 255, 0.2)",
                      border: "none",
                      marginLeft: "auto",
                    }}
                    icon={<FontAwesomeIcon icon={faEllipsis} />}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Dropdown>
              )} */
            // </Flex>
          ),
          // disabled: item.status === "interviewing" || item.status === "failed",
          key: `${TopMenuKeys.RECENT_STUDIES}_${item.id}`,
        }))
        .concat({
          label: (
            <Typography.Text style={{ fontSize: 14, color: "#ffffff" }}>
              See all history
            </Typography.Text>
          ),
          key: TopMenuKeys.SEE_ALL_HISTORY,
          // disabled: false,
        }),
    };

    // Recent Plans (Collapsible menu item)
    const recentPlans: ItemType = {
      label: "Recent Multi-studies",
      key: TopMenuKeys.RECENT_PLANS,
      icon: <PartitionOutlined />,
      children: studyPlansList
        ?.slice(0, 3)
        .map((item) => ({
          label: item.name,
          key: `${TopMenuKeys.RECENT_PLANS}_${item.id}`,
        }))
        .concat({
          label: "See all plans",
          key: TopMenuKeys.SEE_ALL_PLANS,
        }),
    };

    // Rag menu item
    const rag: ItemType = {
      label: "Import Your Data",
      key: TopMenuKeys.RAG,
      icon: <FontAwesomeIcon icon={faUpload} />,
    };

    const items: ItemType[] = [newStudy];

    if (isSignedIn) {
      items.push(recentStudiesItems);
      if (activeSubscription) {
        if (plansEnabled && !!studyPlansList?.length) {
          items.push(recentPlans);
        }

        if (surveysEnabled) {
          items.push(surveys);
        }

        if (ragEnabled) {
          items.push(rag);
        }
      }
    }

    return items;
  }, [
    isSignedIn,
    ragEnabled,
    surveysEnabled,
    plansEnabled,
    activeSubscription,
    studyPlansList,
    recentStudies,
    // deleteStudy,
    // regenerateStudy,
  ]);

  // Top Menu Items Selected Keys
  const topMenuItemsSelectedKeys = useMemo(() => {
    const isHistory = router.asPath.startsWith("/history");
    const isRag = router.asPath.startsWith("/rag");
    const isSurveys = router.asPath.startsWith("/surveys");
    const workspaceKey = workspaces.workspace && `workspace-${workspaces.workspace?.id}`;
    const studyKey = router.query.studyId
      ? `${TopMenuKeys.RECENT_STUDIES}_${router.query.studyId as string}`
      : "";
    const planKey = router.query.planId
      ? `${TopMenuKeys.RECENT_PLANS}_${router.query.planId as string}`
      : "";

    const selectedKeys = [];

    if (workspaceKey) selectedKeys.push(workspaceKey);
    if (studyKey) selectedKeys.push(studyKey);
    if (planKey) selectedKeys.push(planKey);
    if (isSurveys) selectedKeys.push(TopMenuKeys.SURVEYS);
    if (isHistory) selectedKeys.push(TopMenuKeys.SEE_ALL_HISTORY);
    if (isRag) selectedKeys.push(TopMenuKeys.RAG);

    return selectedKeys;
  }, [router.asPath, workspaces.workspace, router.query]);

  // Menu Select Handler
  const onMenuSelect = useCallback(
    async (key: string) => {
      if (key.startsWith(TopMenuKeys.RECENT_STUDIES)) {
        const id = key.split("_")[1];
        if (!id) return;
        router.push(STUDY_ROUTE.getHref(id));
        return;
      }
      if (key.startsWith(TopMenuKeys.RECENT_PLANS)) {
        const id = key.split("_")[1];
        if (!id) return;
        router.push(PLANS_ROUTE.getHref(id));
        return;
      }

      switch (key) {
        case TopMenuKeys.HOME:
          analytics.track("workspace:sidebar:home");
          await router.push(INDEX_ROUTE.getHref());

          handleNewStudy();

          break;
        case TopMenuKeys.SURVEYS:
          analytics.track("workspace:sidebar:surveys");
          router.push(SURVEYS_ROUTE.getHref());
          break;
        case TopMenuKeys.RAG:
          analytics.track("workspace:sidebar:rag");
          router.push(RAG_ROUTE.getHref());
          break;
        case TopMenuKeys.SEE_ALL_HISTORY:
          analytics.track("workspace:sidebar:all-history");
          router.push(HISTORY_ROUTE.getHref());
          break;
        case TopMenuKeys.SEE_ALL_PLANS:
          analytics.track("workspace:sidebar:all-plans");
          router.push(PLANS_ROUTE.getHref());
          break;
      }
    },
    [router]
  );

  return (
    <>
      {/* Top Menu */}
      <Menu
        selectedKeys={topMenuItemsSelectedKeys}
        className="layout-menu"
        theme="dark"
        defaultOpenKeys={collapsed ? [] : [TopMenuKeys.RECENT_STUDIES, TopMenuKeys.RECENT_PLANS]}
        style={{ backgroundColor: "transparent" }}
        mode="inline"
        items={topMenuItems}
        onClick={(info) => onMenuSelect(info.key)}
      />
    </>
  );
};
