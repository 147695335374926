import AntIcon from "@ant-design/icons";
import { faLock, faMoon, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Result, Spin } from "antd";
import { Container } from "components/ui-kit/components/container";
import { CSSProperties } from "react";
import { TransportFailure } from "../../logic/internals/transports/transported-data/transport-failures";

type Props = {
  error: TransportFailure;
  style?: CSSProperties;
  buttonAction?: () => void;
};

export function ErrorFeedback(props: Props) {
  const title = (() => {
    if (props.error === TransportFailure.NotFound) {
      return "Not found";
    } else if (
      props.error === TransportFailure.ConnectionFailure ||
      props.error === TransportFailure.UnexpectedResponse
    ) {
      return "Oops!";
    } else if (props.error === TransportFailure.Forbidden) {
      return "Not Allowed";
    } else if (props.error === TransportFailure.AbortedAndDealtWith) {
      return "Redirecting...";
    } else if (props.error === TransportFailure.PreparingSyntheticUsersFailed) {
      return "Try again.";
    }
  })();

  const subTitle = (() => {
    if (
      props.error === TransportFailure.ConnectionFailure ||
      props.error === TransportFailure.UnexpectedResponse
    ) {
      return "Our synthetic users are currently snoozing and not giving any feedback. We're trying to bribe them with digital donuts, but no luck yet. Please come back later when they're awake and ready to provide their expert opinions. Thank you for understanding!";
    } else if (props.error === TransportFailure.Forbidden) {
      return "You are not allowed to access this content.";
    } else if (props.error === TransportFailure.PreparingSyntheticUsersFailed) {
      return "Sometimes our Synthetic Users fall a sleep and a small poke is all that’s needed to wake them up.";
    }
  })();

  const status = (() => {
    if (props.error === TransportFailure.AbortedAndDealtWith) {
      return "info" as const;
    }
    if (props.error === TransportFailure.NotFound) {
      return "warning" as const;
    }
    if (props.error === TransportFailure.Forbidden) {
      return "warning" as const;
    }
    if (props.error === TransportFailure.PreparingSyntheticUsersFailed) {
      return "warning" as const;
    } else {
      return "error" as const;
    }
  })();

  return (
    <Container>
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        icon={
          <AntIcon
            component={() => {
              if (props.error === TransportFailure.NotFound) {
                return <FontAwesomeIcon icon={faSearch} />;
              } else if (
                props.error === TransportFailure.ConnectionFailure ||
                props.error === TransportFailure.UnexpectedResponse
              ) {
                return <FontAwesomeIcon icon={faMoon} />;
              } else if (props.error === TransportFailure.Forbidden) {
                return <FontAwesomeIcon icon={faLock} />;
              } else if (props.error === TransportFailure.AbortedAndDealtWith) {
                return <Spin size="large" />;
              } else {
                return null;
              }
            }}
          />
        }
        extra={
          props.error === TransportFailure.PreparingSyntheticUsersFailed ? (
            <>
              <div>
                <Button type="primary" onClick={() => props.buttonAction?.()}>
                  Retry
                </Button>
              </div>
            </>
          ) : null
        }
        style={props.style}
      />
    </Container>
  );
}
