import { useAuthContext } from "logic/auth-context";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useGetWorkspacesListQuery, setWorkspaceMode } from "store/modules/workspaces/slice";
import { getLatestWorkspace } from "store/modules/workspaces/utils/get-latest-workspace";
import { useRouter } from "next/router";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useCurrentWorkspaceKeyInput } from "./current-workspace-key-input";
import { useGetCurrentUserQuery } from "store/modules/users/slice";

const useGetWorkspace = () => {
  const dispatch = useAppDispatch();
  const auth = useAuthContext();
  const router = useRouter();
  const workspaceId = useAppSelector((state) => state.workspaces.data.workspace?.id);
  const workspacesLoading = useAppSelector((state) => state.workspaces.loading);
  const workspacesError = useAppSelector((state) => state.workspaces.error);
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
  const currentUser = useAppSelector((state) => state.currentUser.data.currentUser);

  const { getWorkspaceKeyInput, removeWorkspaceKeyInput } = useCurrentWorkspaceKeyInput();

  const localStorageWorkspaceKey = useMemo(() => getWorkspaceKeyInput(), [getWorkspaceKeyInput]);

  const { studyId } = router.query;

  const isSavedStudy = useMemo(() => studyId !== undefined, [studyId]);

  const isVerified = useMemo(
    () =>
      currentUser && auth.session.user && auth.session.user.emailVerified && !auth.session.loading,
    [currentUser, auth.session.user, auth.session.loading]
  );

  useGetCurrentUserQuery(undefined, { skip: !!currentUser || !auth.session.user });

  useGetWorkspacesListQuery(undefined, {
    skip: !isVerified,
  });

  useEffect(() => {
    if (isVerified && !workspaceId && !workspacesLoading && !workspacesError) {
      getLatestWorkspace();
    }
  }, [isVerified, workspaceId, workspacesLoading, dispatch, workspacesError]);

  // cleanup workspace key input from local storage
  useEffect(() => {
    if (localStorageWorkspaceKey) {
      removeWorkspaceKeyInput();
    }
  }, [localStorageWorkspaceKey, removeWorkspaceKeyInput]);

  useEffect(() => {
    if (isSavedStudy && workspaceMode !== WorkspaceMode.SavedStudy) {
      dispatch(setWorkspaceMode(WorkspaceMode.SavedStudy));
    } else if (!isSavedStudy && workspaceMode !== WorkspaceMode.Regular) {
      dispatch(setWorkspaceMode(WorkspaceMode.Regular));
    }
  }, [dispatch, workspaceMode, isSavedStudy]);
};

export default useGetWorkspace;
