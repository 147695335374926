import { WORKSPACE_PLANS_ROUTE } from "@/templates/workspace-plans/workspace-plans-routes";
import { Alert, Button } from "antd";
import Link from "next/link";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";

export const NoInterviewsLeftBanner = () => {
  const workspace = useAppSelector((state) => state.workspaces.data.workspace);
  const subscriptionStats = useMemo(
    () => workspace?.subscriptionUsageStats,
    [workspace?.subscriptionUsageStats]
  );

  if (!workspace?.activeSubscription || !subscriptionStats) {
    return null;
  }

  const remainingInterviews = Math.max(
    0,
    subscriptionStats.interviewsLimit - subscriptionStats.interviewsUsage
  );

  if (remainingInterviews > 0) {
    return null;
  }

  return (
    <Alert
      message="No interviews left"
      description={`You have used all ${subscriptionStats.interviewsLimit} interviews included in your ${subscriptionStats.plan} plan. Please upgrade your plan to conduct more interviews.`}
      type="warning"
      showIcon
      className="my-2 ml-7 mr-8"
      action={
        <Link href={WORKSPACE_PLANS_ROUTE.getHref()}>
          <Button type="primary">See Plans</Button>
        </Link>
      }
    />
  );
};
