export const WORKSPACE_SETTINGS_ROUTE = {
  label: "Workspace Settings",
  path: "/workspace-settings",
  getHref: (workspaceId?: string) =>
    `${WORKSPACE_SETTINGS_ROUTE.path}${workspaceId ? `/${workspaceId}` : ""}`,
};

export const WORKSPACE_SIGNUP_ROUTE = {
  path: `${WORKSPACE_SETTINGS_ROUTE.path}/workspace-sign-up`,
  getHref: () => WORKSPACE_SIGNUP_ROUTE.path,
};
