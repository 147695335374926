import { Breadcrumb } from "antd";
import { WorkspacesMenu } from "./workspaces-menu";
import { ProjectsMenu } from "./projects-menu";
import { useGetCurrentPathBreadcrumb } from "logic/hooks/get-current-path-breadcrumb";
import { useAppSelector } from "store/hooks";

const Breadcrumbs: React.FC = () => {
  const { currentPathBreadcrumb, showProjectBreadcrumb } = useGetCurrentPathBreadcrumb();
  const workspace = useAppSelector((state) => state.workspaces.data?.workspace);

  const items = [
    {
      title: <WorkspacesMenu />,
    },
    ...(showProjectBreadcrumb ? [{ title: <ProjectsMenu /> }] : []),
    ...(currentPathBreadcrumb
      ? [
          {
            title: (
              <span style={{ color: "#000000", fontWeight: "bold" }}>{currentPathBreadcrumb}</span>
            ),
          },
        ]
      : []),
  ];

  if (!workspace) {
    return null;
  }

  return <Breadcrumb items={items} style={{ margin: "16px 0" }} />;
};

export default Breadcrumbs;
