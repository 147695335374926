import { theme } from "antd";
import { CSSProperties, ReactNode } from "react";

export function Container(props: {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  maxWidth?: number;
}) {
  const { token } = theme.useToken();

  return (
    <div
      className={props.className || ""}
      style={{
        paddingLeft: token.padding,
        paddingRight: token.padding,
        maxWidth: props.maxWidth ?? "800px",
        margin: "auto",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}
