import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const SIDEBAR_MENU_COLLAPSED_KEY = "sidebar-menu-collapsed";

export function useSidebarMenuCollapsed() {
  const localStorage = useLocalStorage();

  const getSidebarMenuCollapsed = useCallback(
    () =>
      localStorage.getItem(z.boolean({ coerce: true }).optional(), SIDEBAR_MENU_COLLAPSED_KEY) ??
      false,
    [localStorage]
  );

  const toggleSidebarMenuCollapsed = useCallback(
    (value: boolean) => {
      localStorage.setItem(SIDEBAR_MENU_COLLAPSED_KEY, value);
    },
    [localStorage]
  );

  return useMemo(
    () => ({
      getSidebarMenuCollapsed,
      toggleSidebarMenuCollapsed,
    }),
    [getSidebarMenuCollapsed, toggleSidebarMenuCollapsed]
  );
}
