import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";
const CURRENT_WORKSPACE_KEY = "workspace-page:current-workspace";

export function useCurrentWorkspaceKeyInput() {
  const localStorage = useLocalStorage();

  const getWorkspaceKeyInput = useCallback(() => {
    return localStorage.getItem(z.string().optional(), CURRENT_WORKSPACE_KEY);
  }, [localStorage]);

  const removeWorkspaceKeyInput = useCallback(() => {
    return localStorage.removeItem(CURRENT_WORKSPACE_KEY);
  }, [localStorage]);

  return useMemo(
    () => ({
      getWorkspaceKeyInput,
      removeWorkspaceKeyInput,
    }),
    [getWorkspaceKeyInput, removeWorkspaceKeyInput]
  );
}
