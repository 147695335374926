import { useEffect } from "react";
import { useRouter } from "next/router";

type Props = {
  href: string;
};

export function Redirect(props: Props) {
  const router = useRouter();

  useEffect(() => {
    if (props.href.includes("://")) {
      window.location.href = props.href;
    } else {
      router.push(props.href);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
