import { useRouter } from "next/router";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useMemo } from "react";
import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { z } from "zod";

const IS_IMPERSONATING_KEY = "workspace:is-impersonating";

export const useImpersonateLogin = () => {
  const localStorage = useLocalStorage();
  const router = useRouter();

  const token = router.query.__upgrade_access;

  const isImpersonating = useMemo(() => {
    return localStorage.getItem(z.boolean({ coerce: true }), IS_IMPERSONATING_KEY);
  }, [localStorage]);

  if (token) {
    const auth = getAuth();
    signInWithCustomToken(auth, token as string)
      .then(() => {
        router.push(router.pathname);
        localStorage.setItem(IS_IMPERSONATING_KEY, true);
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        new Error(`Error signing in with custom token: ${error}`);
      });
  }

  return useMemo(
    () => ({
      isImpersonating,
    }),
    [isImpersonating]
  );
};
