import { getEnumValues } from "../../utils/enums/get-enum-values";
import { TransportFailure } from "./transport-failures";

const transportFailures: string[] = getEnumValues(TransportFailure);

export function isTransportFailure(t: string | undefined): t is TransportFailure {
  return !!t && transportFailures.includes(t);
}

export function isSubscriptionRelatedTransportFailure(
  t: string | undefined
): t is TransportFailure {
  return (
    t === TransportFailure.WorkspaceSeatsLimitReached ||
    t === TransportFailure.CustomScriptTooManyQuestions ||
    t === TransportFailure.InterviewsLimitReached ||
    t === TransportFailure.PlanUpgradeNeeded
  );
}
